@import "../../constants";

.title {
    text-align: left;
    display: flex;
}

.statement {
  margin-top: 10px;
  @include phone {
    margin-bottom: 15px;
  }
}

.story {
  background-attachment: fixed;
  &-container {
    text-align: left;
    display: flex;
    flex-direction: column;

    padding-bottom: 20px;
    @media (min-width: 1200px) {
      padding-bottom: 300px !important;
    }
    @media (min-width: 900px) {
      padding-bottom: 200px;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 40%;
    @include phone {
      flex-direction: column;
    }
  }

  &-divider {
    border: 1.5px solid;
    width: 100%;
    margin-left: 0px;
  }

  &-text {
    padding: 20px, 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: $font-regular;
    font-size: $text-medium;
    p {
      margin-top: 15px;
    }
    @include phone {
      width: 100%;
      p {
        margin-top: 10px;
      }
    }
  }

  &-events {
    margin-top: 30px;
    height: 400px;
    display: grid;
    text-align: center;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto;
    grid-template-areas:
      "prep-image hack-image connect-image"
      "prep-caption hack-caption connect-caption";

    .prep-image, .hack-image, .connect-image {
      align-self: flex-end;
      justify-self: center;
      width: 80%;
      margin: 10px 0;
      border: 1.5px solid black;
      height: 90%;
    }

    &-selector {
      display: block;
      margin-bottom: -60px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      width: 10% !important;
      transition: transform 0.2s linear;
      z-index: -100;
      position: relative;
    }

    @include tablet {
      display: none !important;
    }
  }

  &-caption {
    width: 100%;
    justify-self: center;
    margin-top: 10px;
  }

  &-title {
    border-style: solid;
    border-width: 1.5px;
    height: 50px;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 83%;
    justify-content: left;
  }

  &-description {
    border-style: solid;
    border-width: 1.5px;
    width: 70%;
    padding: 0px 5%;
    margin: auto;
    min-height: 40px;
    max-height: 40px;
    display: flex;

    @include tablet {
      max-height: 300px;
      min-height: 200px;
      width: 100%;
      padding: 0;
    }
  }

  &-details {
    width: 90%;
    margin: auto;
  }

  &-intro {
    font-size: 1.1em;
    font-weight: 500;
    padding-top: 2%;
    padding-left: 30%;
    padding-right: 30%;
    @include tablet {
      padding-bottom: 0;
    }
  }
}

.story-mobile {
  display: none;
  padding-top: 50px;
  @include tablet {
    display: block;
    margin-bottom: 50px;
  }
}

.story-slider-container {
  height: 100%;
  position: relative;
  margin-bottom: 45px;
  justify-content: center;
  max-height: 68vh;
}

.testimonials-mobile-image {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1.5px solid black;
  height: 85%;
}

.mobile-text {
  position: relative;
  width: 90%;
  justify-content: center;
  margin-top: 3em;
  text-align: left;

  p {
    margin-bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.name {
  padding-left: 38%;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  margin: auto 0px;
  @media (max-width: 500px) {
    padding-left: 5%;
  }
}

.testimonials {
  padding-top: 8%;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
}

.star {
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: left;
}

.star2 {
  padding-top: 1.5%;
  padding-bottom: 1%;
  padding-left: 2%;
  width: 35px;
}

.bubble-container1 {
  position: relative;
  display: inline-block; /* This makes the container's size adjust to its content */
  margin-top: 30px;
  margin-bottom: 30%;
  width: 250%;
  text-align: left;
}

.bubble-image {
  display: block;
  width: 100%;
  height: 100%;
}

.bubble-text {
  position: absolute;
  top: 60%; /* Centering the text vertically */
  left: 50%; /* Centering the text horizontally */
  transform: translate(-50%, -50%); /* Ensures true centering */
  text-align: center;
  width: 90%; /* Adjust this depending on your speech bubble design */
  padding: 10px;
  font-size: 15px;
  @media (min-width: 1242px) {
    font-size: 18px;
  }
}

.bubble-container2 {
  position: relative;
  display: inline-block; /* This makes the container's size adjust to its content */
  margin-top: 30px;
  margin-bottom: 30%;
  margin-left: -75%;
  width: 250%;
  text-align: center;
}

.bubble-container3 {
  position: relative;
  display: inline-block; /* This makes the container's size adjust to its content */
  margin-top: 30px;
  margin-bottom: 30%;
  margin-left: -150%;
  width: 250%;
  text-align: right;
}

.mobile-bubble-image {
  display: block;
  width: 100%;
  height: 100%;
}

.mobile-bubble-container {
  position: relative;
  display: inline-block; /* This makes the container's size adjust to its content */
  margin-top: 5%;
  margin-bottom: 5%;
  width: 80%;

  margin-left: 10%;
  text-align: center;
}

.mobile-bubble-text {
  position: absolute;
  top: 60%; /* Centering the text vertically */
  left: 50%; /* Centering the text horizontally */
  transform: translate(-50%, -50%); /* Ensures true centering */
  text-align: center;
  width: 90%; /* Adjust this depending on your speech bubble design */
  padding: 10px;
  font-size: 25px;
  @media (min-width: 300px) and (max-width: 380px) {
    font-size: 9px;
  }
  @media (min-width: 381px) and (max-width: 430px) {
    font-size: 11px;
  }
  @media (min-width: 431px) and (max-width: 780px) {
    font-size: 16px;
  }
}
.pic2 {
  background-color: #A8EFE466;
  img {
    width: 70%;
    padding: 20% 15%;
  }
}

.pic3 {
  background-color: #ADB4F966;
  img {
    width: 70%;
    padding: 20% 15%;
  }
}
