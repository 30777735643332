@import "../../constants";

.section {
    &-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    &-container {
        width: $width-lg-max;
        padding-top: 80px;
        padding-bottom: 16px;
        @include tablet {
            width: 100%;
        }
        @include phone {
            padding-top: 70px;
        }
    }
    &-content {
        padding: 0 100px;
        @include tablet {
            padding: 0 30px;
        }
    }
}
