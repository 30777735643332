// Font

$font-logo: "Lexend";
$font-title: "Quicksand Bold";
$font-bold: "Poppins Bold";
$font-regular: "Poppins";

$text-xxsmall: 10px;
$text-xsmall: 14px;
$text-small: 18px;
$text-medium: 24px;
$text-large: 36px;
$text-xlarge: 64px;
$text-xxlarge: 85px;
$text-logo: 36px;

@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: "#{$name}";
        font-style: $style;
        src: url("./resources/fonts/#{$file}.ttf") format("truetype");
    }
}

@include fontface("Lexend", "Lexend/Lexend-Medium");
@include fontface("Quicksand", "Quicksand/static/Quicksand-Regular");
@include fontface("Quicksand Bold", "Quicksand/static/Quicksand-Bold");
@include fontface("Poppins", "Poppins/Poppins-Regular");
@include fontface("Poppins Bold", "Poppins/Poppins-Medium");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

// Color Scheme

$accent-pink: #FF6078;
$accent-pink-focus: #c95566;
$light-pink: #FFE4E1;
$accent-purple: #7676e8;
$accent-purple-focus: #6363d3;
$background-light: #fffdfa;
$background-dark: #19123c;
$text-light: #fffdfa;
$text-muted: #c4c4c4;
$text-dark: #19123c;
$grey: #c4c4c4;
$light-grey: #e5e9f2;
$light-blue: #C0CBF2;
$dark-blue: #7D92DB;

@mixin lightmode() {
    --text-color: #19123c;
    --background-color: #FFFAF3;
    --faq-card-bg-color: #C0CBF2;
    --svg-brightness: 0;
    --header-color: #FFD5CF;
    // --footer-color: #FFD5CF;
    --x-icon: url("./resources/images/icons/cross.svg");
    --hamburger-icon: url("./resources/images/icons/hamburger.svg");
    --sponsor-logos: transparent;
    --nav-shadow-color: #FFD5CF;
    --image-shadow-color: #FF6078;
    --mlh-logo: url("https://static.mlh.io/brand-assets/logo/official/mlh-logo-black.svg");
}

@mixin darkmode() {
    --text-color: #fffdfa;
    --background-color: #19123c;
    --faq-card-bg-color: #3D3D75;
    --svg-brightness: 100;
    --header-color: #3D3D75;
    --footer-color: #3D3D75;
    --x-icon: url("./resources/images/icons/cross-light.svg");
    --hamburger-icon: url("./resources/images/icons/hamburger-light.svg");
    --sponsor-logos: #C0CBF2;
    --nav-shadow-color: #19123c;
    --image-shadow-color: #FFA69E;
    --mlh-logo: url("https://static.mlh.io/brand-assets/logo/official/mlh-logo-white.svg");

}

// Breakpoints

$width-xsm-max: 340px;
$width-sm-max: 650px;
$width-md-max: 900px;
$width-mld-max: 1250px;
$width-lg-max: 1435px;

@mixin small-phone {
    @media (max-width: #{$width-xsm-max}) {
        @content;
    }
}
@mixin phone {
    @media (max-width: #{$width-sm-max}) {
        @content;
    }
}
@mixin tablet {
    @media (max-width: #{$width-md-max}) {
        @content;
    }
}
@mixin desktop {
    @media (max-width: #{$width-mld-max}) {
        @content;
    }
}
