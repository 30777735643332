@import "../../constants";

.faq {
  &-container {
    text-align: center;
  }
  &-title {
    text-align: left;
  }
  &-questions {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
    @include tablet {
      flex-direction: column;
      grid-template-columns: 100%;
    }
  }
  &-left-col,
  &-right-col {
    > * {
      margin-bottom: 1rem;
    }
  }
  &-left-col > :nth-last-child(1),
  &-right-col > :nth-last-child(1) {
    margin-bottom: 0;
  }
  &-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    @include phone {
      width: 300px;
    }
    > a {
      font-family: $font-bold;
      font-weight: bold;
    }
    > img {
      max-width: 276px;
      padding-top: 40px;
    }
  }

  &-all-button {
    padding-bottom: 20px;
  }
}

.landing-button {
  padding: 13px;
  display: inline-flex;
  align-items: center;
  height: auto;
  gap: 10px;
  background-color: white;
  color: $text-dark;

  font-size: 16px;
  font-family: "Poppins";
  font-weight: 700;
  text-decoration: none;
  border-style: solid;
  white-space: nowrap;
  box-shadow: 10px 10px 0px 0px #19123c;
  @include phone {
    font-size: 14px;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    box-shadow: 0 0 11px rgba(255, 96, 120, 0.5);
    transform: translateY(-5px);
    transition: 0.1s linear;
  }

  img {
    width: 20px;
  }

  .label {
    margin-right: auto;
  }
}

