@import "../../constants";

.contact-button-container {
  text-align: center;
  padding-bottom: 50px;
}

@keyframes bounce-up {
  0% {
    transform: translateY(50px);
    opacity: 1;
  }
  50% {
    transform: translateY(30px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(50px);
    opacity: 1;
  }
}

.contact-container {
  text-align: left;
  margin: 0 auto;
  color: #19123c;
  font-size: 64px;
  font-family: Lexend;
  font-weight: 600;
  @include phone {
    font-size: 32px;
  }
}

.email-input {
  min-width: 300px;
  width: 90%;
  height: 48px;
  border: 2px solid #19123c;
  font-family: "Poppins";
  left: -53.88%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  @include phone {
    min-width: 200px;
    width: 90%;
    height: 48px;
    border: 2px solid #19123c;
    font-family: "Poppins";
    left: -53.88%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    @include phone {
      min-width: 150px;
      width: 80%;
      font-size: 10px;
    }
  }
}

.email-wrapper {
  margin-top: 16px;
  position: relative;
  margin-bottom: 16px;
  display: flex;
  width: 90%;
  input {
    padding: 0px 5px;
    width: 100px;
    flex-grow: 1;
  }
}

.submit-wrapper {
  cursor: pointer;
  size: 100em;
  width: 47px;
  height: 39px;
  margin-top: 5px;
  right: 0;
  border-radius: 3px;
  display: inline;
  position: absolute;
  &:hover {
    opacity: 0.7;
  }
}

.submit-btn {
  width: 25px;
  height: 25px;
  margin: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contact-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @include tablet {
    flex-direction: column;
  }
}

.contact-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  img {
    width: 100%;
    animation: bounce-up 3s linear infinite;
  }
  @include tablet {
    padding: 0px 0 60px 0;
    width: 100%;
    justify-content: flex-start;
    img {
      width: 60%;
    }
  }
  @include phone {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.contact {
  min-width: 550px;
  width: 50%;
  @include tablet {
    width: 100%;
  }
  @include phone {
    min-width: 0;
  }
}
.contact-links {
  text-decoration: none;
}

.contact-links:hover {
  text-decoration: underline;
}

.socials-row {
  max-width: 500px;
  margin-top: 8px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  img {
    padding: 20px 0;
    &:hover {
      opacity: 0.7;
    }
  }
}

.links-row {
  max-width: 450px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  a {
    font-family: "Poppins";
  }
}

.team-title {
  margin-top: 32px;
}

.inclusivity-statement {
  &-container {
    padding-top: 80px;
    text-align: left;

    @include phone {
      padding-top: 60px;
    }
  }
  &-title {
    font-family: $font-title;
    font-size: 25px;
    line-height: 45px;
    margin: 50px 0px 20px 0px;
  }
  &-content {
    font-family: "Poppins";

    font-weight: 400;
    font-size: 15px;
    margin-bottom: 100px;
    @include phone {
      margin-bottom: 50px;
    }
  }
}

.contact-method {
  background-color: white;
  &-container {
    text-align: left;
    @include phone {
      height: auto;
    }
    a {
      &:hover {
        opacity: 0.5;
        transform: translateY(-5px);
        transition: 0.1s linear;
      }
    }
  }
  &-title {
    margin-top: 50px;
    margin-bottom: 24px;
    @include phone {
      text-align: left;
    }
  }
  &-logos {
    padding: 36px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .contact {
    min-width: 550px;
    width: 50%;
    @include tablet {
      width: 100%;
    }
    @include phone {
      min-width: 0;
    }
  }

  .contact-links {
    text-decoration: none;
  }

  .contact-links:hover {
    text-decoration: underline;
  }

  .socials-row {
    max-width: 500px;
    margin-top: 8px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    img {
      padding: 20px 0;
      &:hover {
        opacity: 0.7;
      }
    }
    @include phone {
      max-width: 250px;
      gap: 20px;
    }
  }

  .links-row {
    max-width: 450px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      font-family: "Poppins";
    }
  }

  .team-title {
    margin-top: 32px;
  }

  .inclusivityStatement {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 45px;
    color: #19123c;
    @include phone {
      font-size: 24px;
    }
  }

  .statement {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #19123c;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    @include phone {
      font-size: 16px;
    }
  }

  .contact-method {
    background-color: white;
    &-container {
      text-align: left;
      @include phone {
        height: auto;
      }
      a {
        &:hover {
          opacity: 0.5;
          transform: translateY(-5px);
          transition: 0.1s linear;
        }
      }
    }
    &-title {
      margin-top: 50px;
      margin-bottom: 24px;
      @include phone {
        text-align: left;
      }
    }
    &-logos {
      padding: 36px;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a {
        width: 90%;
        align-self: center;
        justify-self: center;
        text-align: center;
        img {
          width: 100%;
          text-align: center;
        }
      }
      @include phone {
        padding: 16px;
      }
    }
    &-gold {
      width: 60%;
      align-content: center;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      a {
        padding-top: 20px;
        max-width: 650px;
        text-align: center;
      }
      @include tablet {
        a {
          max-width: 450px;
        }
      }
      @include phone {
        width: 100%;
        text-align: center;
      }
    }
    @include phone {
      padding: 16px;
    }
  }
  &-gold {
    width: 60%;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    a {
      padding-top: 20px;
      max-width: 650px;
      text-align: center;
    }
    @include tablet {
      a {
        max-width: 450px;
      }
    }
    @include phone {
      width: 100%;
      a {
        padding-top: 10px;
        max-width: none;
      }
    }
  }
  &-silver {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
    display: grid;
    gap: 20px;
    @include tablet {
      gap: 0px;
    }
    @include phone {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 40px;
      justify-content: space-around;
      gap: 8px;
      a {
        img {
          max-width: 100%;
        }
      }
    }
  }
  &-bronze {
    margin-top: 60px;
    width: 50%;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    a {
      max-width: 200px;
    }
    @include tablet {
      width: 60%;
      a {
        min-width: 80px;
      }
    }
    @include phone {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 40px;
      gap: 8px;
      width: 90%;
      a {
        width: 100%;
        img {
          height: 100%;
        }
      }
    }
  }
  &-startup {
    margin-top: 60px;
    width: 18%;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    a {
      min-width: 60px;
    }
    @include tablet {
      width: 25%;
      a {
        min-width: 50px;
      }
    }
    @include phone {
      width: 38%;
      margin-top: 20px;
      gap: 16px;
      a {
        min-width: 0;
        width: 100%;
        img {
          height: 100%;
        }
      }
    }
  }
  &-shadow-container {
    background-color: white;
    border: 2px solid #19123c;
    box-shadow: 20px 20px 0px #19123c;
  }
  &-tab {
    background-color: #a8efe4;
    display: flex;
    height: 60px;
    border-bottom: 2px solid #19123c;
    justify-content: flex-end;
    align-items: center;
  }
  &-square {
    margin-right: 7px;
    background-color: white;
    height: 25px;
    width: 25px;
    border: 2px solid #19123c;
  }
  &-partners {
    background-color: white !important;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    border-bottom: 2px solid #19123c;
    border-left: 2px solid #19123c;
    border-right: 2px solid #19123c;
    a {
      width: 40%;
      max-width: 300px;
      padding: 0 20px;
      img {
        width: 100%;
      }
    }
    @include tablet {
      a {
        margin-top: 20px;
        width: 50%;
      }
    }
    @include phone {
      padding: 0 0 20px 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a {
        width: 80%;
        max-width: 250px;
      }
    }
  }
}

.footer-container {
  background-color: white;
  margin: 0 auto;
  margin-top: 3.5%;
  padding-top: 10%;
  padding-bottom: 10%;
  @include phone {
    padding-top: 100px;
    padding-bottom: 30%;
    min-width: 90%;
  }
}

.footer-title {
  background-color: white;
}
