@import "../../constants";

.button {
  padding: 13px;
  display: inline-flex;
  align-items: center;
  height: auto;
  gap: 10px;
  background-color: white;
  color: $text-dark;

  font-size: 16px;
  font-family: "Poppins";
  font-weight: 700;
  text-decoration: none;
  border-style: solid;
  white-space: nowrap;
  box-shadow: 10px 10px 0px 0px #19123c;
  @include phone {
    font-size: 14px;
  }

  &:hover {
    opacity: 0.7;
    box-shadow: 0 0 11px rgba(255, 96, 120, 0.5);
    transform: translateY(-5px);
    transition: 0.1s linear;
  }

  img {
    max-width: 20px;
    max-height: 20px;
  }

  .label {
    margin-right: auto;
  }
}
