@import '../../constants';

.our-sponsors {
  &-container {
    text-align: left;
    @include phone {
      height: auto;
    }
    a {
      &:hover {
        opacity: 0.5;
        transform: translateY(-5px);
        transition: 0.1s linear;
      }
    }
  }

  &-title {
    margin-top: 50px;
    margin-bottom: 24px;
    @include phone {
      text-align: left;
    }
  }

  &-logos {
    padding: 36px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      width: 90%;
      align-self: center;
      justify-self: center;
      text-align: center;
      img {
        width: 100%;
        text-align: center;
      }
    }
    @include phone {
      padding: 16px;
    }
  }

  &-gold {
    width: 60%;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    a {
      padding-top: 20px;
      max-width: 650px;
      text-align: center;
    }
    @include tablet {
      a {
        max-width: 450px;
      }
    }
    @include phone {
      width: 100%;
      a {
        padding-top: 10px;
        max-width: none;
      }
    }
  }

  &-silver {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
    display: grid;
    gap: 20px;
    @include tablet {
      gap: 0px;
    }
    @include phone {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 40px;
      justify-content: space-around;
      gap: 8px;
      a {
        img {
          max-width: 100%;
        }
      }
    }
  }

  &-bronze {
    margin-top: 60px;
    width: 50%;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    a {
      max-width: 200px;
    }
    @include tablet {
      width: 60%;
      a {
        min-width: 80px;
      }
    }
    @include phone {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 40px;
      gap: 8px;
      width: 90%;
      a {
        width: 100%;
        img {
          height: 100%;
        }
      }
    }
  }

  &-startup {
    margin-top: 60px;
    width: 18%;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    a {
      min-width: 60px;
    }
    @include tablet {
      width: 25%;
      a {
        min-width: 50px;
      }
    }
    @include phone {
      width: 38%;
      margin-top: 30px;
      gap: 16px;
      a {
        min-width: 0;
        width: 100%;
        img {
          height: 100%;
        }
      }
    }
  }
  &-shadow-container {
    box-shadow: 20px 20px #19123C;
  }
  &-tab{
    background-color: #adb4f9;
    display: flex;
    height: 60px;
    border: 2px solid #19123c;
    justify-content: flex-end;
    align-items: center; 
  }
  &-square {
    margin-right: 10px;
    background-color: white;
    height: 30px;
    width: 30px;
    border: 2px solid #19123c;
  }
  &-partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background-color: white !important;
    height: 550px;
    justify-content: space-evenly;
    padding: 20px;
    border-bottom: 2px solid #19123c;
    border-left: 2px solid #19123c;
    border-right: 2px solid #19123c;
    a {
      width: 40%;
      max-width: 300px;
      padding: 0 20px;
      img {
        width: 100%;
      }
    }
    // @include tablet {
    //   a {
    //     margin-top: 30px;
    //     width: 50%;
    //   }
    // }
    @include phone {
      height: auto;
      padding: 0 0 20px 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a {
        width: 80%;
        max-width: 250px;
        padding: 0 30px;
      }
    }
    &-row {
      display: flex;
      // width: 685px;
      align-items: center;
      gap: 70px;
      justify-content: center;

      @include phone {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
