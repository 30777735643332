@import "../../constants";

.carousel {
  &-box {
    display: flex;
    flex-direction: column;
    height: 500px;
    border-style: solid;
    border-width: 2px;
    box-shadow: 20px 20px #19123c;
    background-color: #ffffff;
    width: 85%;
    @include tablet {
      height: 580px;
    }

    @include phone {
      height: 620px;
    }
  }

  &-header {
    border-bottom: 2px solid;
    height: 45px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: end;
    .white-box {
      margin: auto;
      border-style: solid;
      border-width: 2px;
      margin-right: 0.5em;
      background-color: #ffffff;
      height: 1.5em;
      width: 1.5em;
    }
  }

  &-wrapper {
    width: 100%;
    @media (min-width: 800px) {
      width: 100%;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 24px;
  }

  &-images-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &-card {
    align-items: center;
    height: 90%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &-link {
    flex: 1;
    margin: 0px 25px;
    display: flex;
    justify-content: center;
  }

  &-text {
    width: 90%;
    margin: 10px;
  }

  &-title {
    text-align: center;
    font-weight: bold;
    p {
      font-size: 25px;
    }
  }

  &-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &-img-wrapper {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      height: 85px;
    }
    @include phone {
      height: 75px;
    }
    &-bronze {
      margin: 10px 0px;
      display: block;
      width: 130px;
      @include phone {
        width: 100px;
      }

      @include small-phone {
        width: 85px;
      }
    }
  }

  &-button-section {
    display: flex;
    column-gap: 40px;
    row-gap: 5%;
    justify-content: center;
    align-items: flex-start;

    @include tablet {
      flex-wrap: wrap;
    }

    @include phone {
      column-gap: 30px;
    }
  }

  &-button {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
}

.career-pixel {
  @media (max-width: 375px) {
    display: none !important;
  }
}

a {
  cursor: pointer;
}

.cursor {
  width: 13px !important;
}
