@import "../../constants";

.about {
  &-container {
    text-align: left;
    display: flex;
    flex-direction: column;

    padding-bottom: 20px;
    @media (min-width: 1200px) {
      padding-bottom: 300px !important;
    }
    @media (min-width: 900px) {
      padding-bottom: 200px;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 40%;
    @include phone {
      flex-direction: column;
    }
  }

  &-divider {
    border: 1px solid;
    width: 100%;
    margin-left: 0px;
  }

  &-text {
    padding: 20px, 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: $font-regular;
    font-size: $text-medium;
    p {
      margin-top: 15px;
    }
    @include phone {
      width: 100%;
      p {
        margin-top: 10px;
      }
    }
  }

  &-events {
    margin-top: 100px;
    height: 400px;
    display: grid;
    text-align: center;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto;
    grid-template-areas:
      "prep-image hack-image connect-image"
      "prep-caption hack-caption connect-caption";

    img {
      align-self: flex-end;
      justify-self: center;
      width: 80%;
      margin: 20px 0;
    }

    &-selector {
      display: block;
      margin-bottom: -60px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      width: 10% !important;
      transition: transform 0.2s linear;
      z-index: -100;
      position: relative;
    }

    @include tablet {
      display: none !important;
    }
  }

  &-caption {
    width: 100%;
    justify-self: center;
    margin-top: 50px;
  }

  &-title {
    border-style: solid;
    border-width: 2px;
    background-color: #dfe5fa;
    width: 90%;
    height: 60%;
    margin: auto;
    text-align: center;
    @include tablet {
      width: 100%;
    }
  }

  &-description {
    border-style: solid;
    border-width: 2px;
    border-top: none;
    background-color: #ffffff;
    width: 80%;
    padding: 0px 5%;
    overflow: hidden;
    margin: auto;
    transition: 0.3s ease;
    min-height: 80px;
    max-height: 90px;

    @include tablet {
      max-height: 300px;
      min-height: 200px;
      width: 100%;
      padding: 0;
    }
  }

  &-details {
    width: 90%;
    margin: auto;
  }

  &-intro {
    font-size: 1.4em;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet {
      padding-bottom: 0;
    }
  }
}

.animate {
  transform: translateY(40px);
  transition: transform 0.3s linear;
  &:hover {
    transform: translateY(-40px);
    transition: transform 0.3s linear;
    .about-description {
      max-height: 300px;
      min-height: 200px;
      padding-bottom: 30px;
      transition: 0.3s ease;
    }
    .about-events-selector {
      display: block;
      transform: translateY(-65px);
    }
    .prep-image {
      filter: drop-shadow(10px 25px 10px #c3c9ff);
    }
    .hack-image {
      filter: drop-shadow(10px 25px 10px #c6ede6);
    }
    .connect-image {
      filter: drop-shadow(10px 25px 10px #f0ccd1);
    }
  }

  @include phone {
    transform: none;
    &:hover {
      transform: none;
      .date {
        display: block;
      }
    }
  }
  @include tablet {
    transform: none;
    &:hover {
      transform: none;
      .date {
        display: block;
      }
    }
  }
}

.about-mobile {
  display: none;
  padding-top: 50px;
  @include tablet {
    display: block;
    margin-bottom: 50px;
  }
}

.about-slider-container {
  position: relative;
  margin-bottom: 45px;
  justify-content: center;
}

.mobile-image {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-bottom: 20px;
  margin-top: 40px;
}

.mobile-text {
  position: relative;
  width: 75%;
  justify-content: center;
  margin: auto;
  margin-top: 3em;
  text-align: center;

  p {
    margin-bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
