@import "./constants";

.App {
  text-align: center;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  // handle ALL dark mode things here
  @include lightmode();
  &.dark-mode {
    @include darkmode();
  }
  background-color: var(--background-color);
  color: var(--text-color);
  transition: all 0.25s linear;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  a {
    color: var(--text-color);
    transition: all 0.25s linear;
    &:hover {
      opacity: 0.7;
    }
  }
  details {
    background-color: var(--faq-card-bg-color);
  }
  hr {
    border: 1px solid #19123c;
    align-self: stretch;
    margin-top: 32px;
    margin-bottom: 60px;
  }
  .nav {
    &-container {
      border-bottom: 2px solid #19123c;
      transition: all 0.25s linear;
      background-color: #fffaf3;
    }
    &-link {
      color: var(--text-color);
      transition: all 0.25s linear;
    }
    &-mobile-links {
      background-color: var(--header-color);
      -webkit-box-shadow: 0px 1px 40px 1px var(--nav-shadow-color);
      -moz-box-shadow: 0px 1px 40px 1px var(--nav-shadow-color);
      box-shadow: 0px 1px 40px 1px var(--nav-shadow-color);
    }
    &-x {
      content: var(--x-icon);
    }
    &-hamburger {
      content: var(--hamburger-icon);
    }
  }
  .learn-more-arrow,
  .faq-arrow,
  .carousel-controls {
    filter: brightness(var(--svg-brightness));
  }
  .footer-container {
    background-color: var(--footer-color);
    transition: all 0.25s linear;
  }
  .our-sponsors-logos,
  .our-sponsors-partners {
    background-color: var(--sponsor-logos);
  }
  // .team {
    // &-text {
    //   background-color: var(--background-color);
    //   transition: all 0.25s linear;
    // }
    // &-photos {
    //   img {
    //     -moz-box-shadow: 5px 5px 0 var(--image-shadow-color);
    //     -webkit-box-shadow: 5px 5px 0 var(--image-shadow-color);
    //     box-shadow: 5px 5px 0 var(--image-shadow-color);
    //   }
    // }
  // }
  .mlh-mobile {
    content: var(--mlh-logo);
  }
}

h1 {
  color: #19123c;
  font-family: Lexend;
  font-weight: 600;
  font-size: $text-xlarge;
  margin: 0;
  @include phone {
    font-size: 36px;
  }
}

h2 {
  font-family: "Quicksand Bold";
  font-size: $text-large;
  margin: 0;
  text-align: left;
  @include phone {
    font-size: 24px;
  }
}

h3 {
  font-family: "Quicksand Bold";
  font-size: 30px;
  margin: 0;
  @include phone {
    font-size: 24px;
  }
}

h4 {
  font-family: "Poppins";
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  margin: 0;
  @include phone {
    font-size: 16px;
  }
}

h5 {
  font-family: "Quicksand";
  font-size: $text-medium;
  margin: 0;
}

p {
  font-family: "Poppins";
  font-size: $text-small;
  margin: 0;
  @include phone {
    font-size: 16px;
  }
}

a {
  font-family: $font-bold;
  font-weight: bold;
}
