@import "../../constants";

.keynote {
  &-title {
    text-align: left;
  }

  &-header {
    background-color: #a8efe4;
    border-bottom: 2px solid;
    height: 4em;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: end;
    .white-box {
      margin: auto;
      border-style: solid;
      border-width: 2px;
      margin-right: 0.5em;
      background-color: #ffffff;
      height: 2em;
      width: 2em;
    }
  }

  &-box {
    border-style: solid;
    border-width: 2px;
    box-shadow: 20px 20px #19123c;
  }

  &-card {
    background-color: #ffffff;
    text-align: left;
    display: grid;
    grid-template-areas:
      "card-img card-title"
      "card-img card-desc";
    grid-template-columns: 35% 65%;
    grid-template-rows: 45% 55%;
    justify-content: center;
    column-gap: 2em;
    align-items: center;
    padding: 3em;

    @media (max-width: 980px) {
      grid-template-areas:
        "card-img"
        "card-title"
        "card-desc";
      grid-template-columns: 100%;
      grid-template-rows: 40% 25% 35%;
      row-gap: 20px;

      &-img {
        margin: auto;
      }
      &-title {
        text-align: center;

        &-box {
          margin: auto;
        }
      }
      .keynote-card-img {
        box-shadow: none !important;
      }
    }

    @media (max-width: 450px) {
      grid-template-rows: 30% 20% 50%;
      &-name {
        font-size: 25px !important;
        line-height: 1.5;
      }

      &-role {
        text-align: left;
      }
    }

    @media (max-width: 350px) {
      padding: 1.5em;
    }

    &-img {
      grid-area: card-img;
      max-width: 90%;
      max-height: 100%;
      border: 2px solid;
      margin: auto;
      border-radius: 10px;
      transition: transform 0.3s linear;
    }

    &-title-box {
      background-color: white;
      border: 2px solid;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column-gap: 20px;
      padding: 8px;
      max-width: 400px;
      margin-bottom: 30px;
    }

    &-star {
      grid-area: card-title;
    }
    &-name {
      grid-area: card-title;
      font-size: 40px;
      padding: 20px 0px;
      font-family: "Press Start 2P", cursive;
    }
    &-role {
      grid-area: card-title;
    }

    &-desc {
      grid-area: card-desc;
    }

    &-desc-wrapper {
      height: 100%;
      display: flex;
      align-items: flex-start;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &:hover {
      .keynote-card-img {
        box-shadow: 15px 15px #a8efe4;
        transform: translateY(-10px);
        transition: transform 0.3s linear;
      }
    }
  }
}
