@import "../../constants";

.footer {
    background-color: white;
    &-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        display: inline;
        color: #19123C;
        @include phone {
            font-size: 15px;
            line-height: 20px;
        }
    }
    
    &-container {
        box-sizing: border-box;
        background-color: white;
        border: 2px solid hsl(250, 54%, 15%);
        height: 200px;
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        @include phone {
            height: 114px;
        }
    }
    &-email-box {
        box-sizing: border-box;
        background-color: white;
        border: 2px solid #19123C;
        left: -53.88%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
